import React from 'react';

import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';

import ContactLayout from '../components/contact/ContactLayout';

export default function ContactPage() {
  return (
    <Layout>
      <PageTitle title="Contact" head="contacthead" />
      <ContactLayout title="Contact Me!" />
    </Layout>
  )
}
