import React from 'react';
import Fade from 'react-reveal/Fade';
import Title from '../SectionTitle';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import config from '../../../config';

export default function contactLayout({ title }) {
    return (
        <div>
            <section id="contact-section" className="contact-section" >
                <Container className="contact-wrapper">
                    <Title title={title} />
                    <Fade bottom duration={1000} delay={600} distance="30px">
                        <Row className="contact-wrapper">
                            {
                                config.contact.map(
                                    contact => {
                                        const { name, icon, url, handle } = contact;
                                        return (
                                            <Col md={4} sm={12} className="contact-wrapper">
                                                <Card>
                                                    <Card.Body >
                                                        <Card.Title className='card_title text-center'><i className={`fab ${icon}`}></i></Card.Title>
                                                        <Card.Text className="card_title text-center">{handle}</Card.Text>
                                                        <Card.Text className="card_title text-center">
                                                            <Button className="cta-btn cta-btn--hero" href={url}>{name}</Button>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    }
                                )
                            }
                        </Row>
                    </Fade>
                </Container>
            </section>
        </div>
    );
};